const debounceForSpecialEventUI = function(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default (() => {
  class SpecialEvent {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      this.bopContainer = document.querySelector('.m-aol-se__container_3')
      this.iframes = this.elems.map(e => e.querySelector('iframe')).filter(Boolean)
      this.isNotificationBannerExistsOnHomePage()
      this._addEventListener()
    }

    _addEventListener() {
      window.addEventListener(
        'resize',
        debounceForSpecialEventUI(e => {
          this.isNotificationBannerExistsOnHomePage()
        }, 100)
      )

      if (this.bopContainer?.classList.contains('election_day_app')) {
        const webViewLink = this.bopContainer.dataset?.link
        this.bopContainer.addEventListener('click', () => {
          window.location.href = webViewLink
        })
      }

      window.addEventListener('message', this.handlePostMessage.bind(this))
    }

    handlePostMessage(event) {
      const { data, origin, source } = event
      if (origin !== 'https://s.yimg.com' || typeof data !== 'string') {
        return
      }

      const [, height] = data.split(':')
      this.iframes.forEach(iframe => {
        if (iframe.contentWindow === source) {
          if (!iframe.__initialized) {
            iframe.__initialized = true
          }
          const { clientHeight } = iframe
          const newHeight = parseInt(height, 10) || clientHeight
          if (clientHeight !== newHeight) {
            iframe.setAttribute('height', `${newHeight}px`)
          }
        }
      })
    }

    isNotificationBannerExistsOnHomePage() {
      if (window.innerWidth <= 900) {
        if (!this.elems.length) {
          return
        }
        const NOTIFICATION_BANNER_CLASS = '.m-notification-banner'
        const SE_WITHOUT_NOTIFICATION_BANNER_CLASS = 'm-aol-se_without_notif_banner'

        if (document.querySelector(NOTIFICATION_BANNER_CLASS)) {
          this.elems.forEach(elem => {
            elem.classList.remove(SE_WITHOUT_NOTIFICATION_BANNER_CLASS)
          })
        } else {
          this.fixDLOuterWrapperTopMarginForMobile()
          this.elems.forEach(elem => {
            elem.classList.add(SE_WITHOUT_NOTIFICATION_BANNER_CLASS)
          })
        }
      }
    }

    fixDLOuterWrapperTopMarginForMobile() {
      const DL_MOBILE_OUTER_WRAPPER_CLASS = `.dl__outer-wrapper.dl-mobile`
      const DL_MOBILE_OUTER_WRAPPER_SELECTOR = document.querySelector(DL_MOBILE_OUTER_WRAPPER_CLASS)
      if (DL_MOBILE_OUTER_WRAPPER_SELECTOR) {
        DL_MOBILE_OUTER_WRAPPER_SELECTOR.classList.add('dl-special-event')
      }
    }
  }

  return SpecialEvent
})()
